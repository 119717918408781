import axios from "@/utils/axios";

export const list = 'task/list'

export const TaskAdd = (data) => {
    return axios.post(`task/add`, data)
}

export const TaskEdit = (data) => {
    return axios.post(`task/edit`, data)
}

export const TaskDel = (data) => {
    return axios.post(`task/del`, data)
}
export const TaskTypeList = (data) => {
    return axios.get(`task/type_list`, data)
}

