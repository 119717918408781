<template>
    <el-dialog v-model="visible" :title="editorForm.id ? '编辑' : '新增'" width="500px" :before-close="BeforeClose">
    <el-form :model="editorForm" :rules="rulesForm" ref="ruleFormRef" label-width="100px">
        <el-form-item label="小程序任务" prop="appTask">
            <el-select v-model="editorForm.appTask">
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="任务标题" prop="title">
            <el-input v-model="editorForm.title"></el-input>
        </el-form-item>
        <el-form-item label="任务图标" prop="icon">
            <Upload @success="UploadSuccess" ref="uploadRef"/>
        </el-form-item>
        <el-form-item label="任务经验" prop="exp">
            <el-input type="number" v-model="editorForm.exp"></el-input>
        </el-form-item>
        <el-form-item label="小鱼干数量" prop="fishCount">
            <el-input type="number" v-model="editorForm.fishCount"></el-input>
        </el-form-item>
        <el-form-item label="财商币数量" prop="financialCount">
            <el-input type="number" v-model="editorForm.financialCount"></el-input>
        </el-form-item>
        <el-form-item label="卡片" prop="cardId">
            <SelectCard @change="SelectCardChange" ref="selectCardRef"/>
        </el-form-item>
        <el-form-item label="任务周期" prop="period">
            <el-select v-model="editorForm.period">
                <el-option label="天任务" :value="1"></el-option>
                <el-option label="周任务" :value="2"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="type">
            <el-select v-model="editorForm.type">
                <el-option :label="item.name" :value="item.id" v-for="(item, index) in typeItems" :key="index"></el-option>
                <!-- <el-option label="出行一次" :value="2"></el-option>
                <el-option label="答题pk一次" :value="3"></el-option>
                <el-option label="收获小鱼干" :value="4"></el-option>
                <el-option label="分享游戏" :value="5"></el-option> -->
            </el-select>
        </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="EditorSubmit(ruleFormRef)">保存</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
import { inject, nextTick, reactive, ref, watch } from 'vue'

import { TaskAdd, TaskEdit } from '@/api/task'
import Upload from '@/components/upload'
import SelectCard from './selectCard.vue'

export default {
    components: {Upload, SelectCard},
    props: {
        title: {
            type: String,
            default: '新增商品'
        },
        typeItems: {
            type: Array,
            default () {
                return []
            }
        }
    },
    emits: ['submit'],
    setup(props, ctx) {
        const elplus = inject('elplus')
        const rulesForm = reactive({
            appTask: [{ required: true, message: '请选择是否小程序任务', trigger: 'blur' }],
            title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
            icon: [{ required: true, message: '请上传图片', trigger: 'change' }],
            exp: [{ required: true, message: '请填写任务经验', trigger: 'blur' }],
            fishCount: [{ required: true, message: '请填写小鱼干数量', trigger: 'blur' }],
            financialCount: [{ required: true, message: '请填写财商币数量', trigger: 'blur' }],
            cardId: [{ required: true, message: '请选择卡片', trigger: 'change' }],
        })
        let visible = inject('taskVisible')
        let editorForm = inject('editorForm')
        let ruleFormRef = ref(null)
        let uploadRef = ref(null)
        let selectCardRef = ref(null)
        
        // 默认赋值
        watch(() => visible.value, () => {
            nextTick(() => {
                if (visible.value && uploadRef.value) {
                    uploadRef.value.SelectedImage(editorForm.value.icon)
                    console.log(editorForm.value)
                    selectCardRef.value.UpdatedSelected({id: editorForm.value.cardId, name: editorForm.value.cardName})
                }
            })
        })
        
        const methods = {
            BeforeClose () {
                visible.value = false
            },
            EditorSubmit () {
                const formEl = ruleFormRef.value;
                if (!formEl) return
                formEl.validate((valid) => {
                    if (valid) {
                        let loading = elplus.loading()
                        let params = {...editorForm.value}
                        params.fishCount = Number(params.fishCount)
                        params.exp = Number(params.exp)
                        params.financialCount = Number(params.financialCount)
                        if (editorForm.value.id) {
                            TaskEdit(params).then(res => {
                                loading.close()
                                if (!res.ret) ctx.emit('submit')
                            })
                        } else {
                            TaskAdd(params).then(res => {
                                loading.close()
                                if (!res.ret) ctx.emit('submit')
                            })
                        }
                    }
                })
            },
            UploadSuccess (res) {
                editorForm.value.icon = res.data
            },
            SelectCardChange (selected) {
                editorForm.value.cardId = selected.id
            }
        }

        return {
            selectCardRef,
            uploadRef,
            ruleFormRef,
            rulesForm,
            visible,
            editorForm,
            ...methods
        }
    },
}
</script>

<style lang="scss" scoped>

</style>